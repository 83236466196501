import { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import AuthContext from './context/AuthContext';
import { setupInterceptors } from './plugins/http';

const InjectAxiosInterceptors = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    setupInterceptors(history, auth);
  }, [history, auth]);

  return null;
};

export default InjectAxiosInterceptors;
