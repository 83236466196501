import { createContext } from 'react';

/**
 * @typedef User
 * @property {String} name
 */

/**
 * @typedef {Object} AuthContext
 * @property {String} token
 * @property {User} user
 */

/**
 * @type AuthContext
 */
const defaultValues = {
  token: null,
  user: null,
  removeToken: () => {},
};

const AuthContext = createContext(defaultValues);

export default AuthContext;
