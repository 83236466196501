import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.aksamedia.co.id/admin',
});

/**
 *
 * @param {import('history').History} history
 * @param {import('../context/AuthContext').AuthContext} authContext
 */
export const setupInterceptors = (history, authContext) => {
  http.interceptors.request.use((config) => {
    if (authContext.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-Auth-Token'] = authContext.token;
    }

    return config;
  }, (err) => Promise.reject(err));
  http.interceptors.response.use((res) => {
    // success
    return res;
  }, (err) => {
    if (err.response?.status === 401) {
      history.push('/login');
      authContext.removeToken();
    }

    return Promise.reject(err);
  });
};

export default http;
