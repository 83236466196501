import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <nav
      className='bg-white w-56 fixed top-0 left-0 bottom-0 shadow-lg
        hidden md:block'
    >
      <div
        className='mt-4 mb-8 text-center'
      >
        <NavLink
          to='/'
          className='text-blue-500 font-semibold'
        >
          Aksamedia
        </NavLink>
      </div>

      <ul className='navs'>
        <li>
          <NavLink
            exact
            to='/'
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/users'
          >
            Users
          </NavLink>
          {pathname.includes('users') && (
            <ul className='navs'>
              <li>
                <NavLink to='/users' exact>All Users</NavLink>
              </li>
              <li>
                <NavLink to='/users/roles' exact>Roles & Permissions</NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink
            to='/posts'
          >
            Posts
          </NavLink>
          {pathname.includes('posts') && (
            <ul className='navs'>
              <li>
                <NavLink to='/posts' exact>All Posts</NavLink>
              </li>
              <li>
                <NavLink to='/posts/new'>Add New</NavLink>
              </li>
              <li>
                <NavLink to='/posts/categories'>Categories</NavLink>
              </li>
              <li>
                <NavLink to='/posts/tags'>Tags</NavLink>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
