import { lazy, Suspense, useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthContext from './context/AuthContext';
import InjectAxiosInterceptors from './InjectAxiosInterceptors';
import http from './plugins/http';
import Sidebar from './views/components/Sidebar';

const Home = lazy(() => import('./views/Home'));
const Login = lazy(() => import('./views/Login'));
const PostRoutes = lazy(() => import('./views/posts/Routes'));
const UserRoutes = lazy(() => import('./views/users/Routes'));

function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  const afterLogin = (payload) => {
    setUser(payload.user);
    setToken(payload.token);
  };

  const getUserLogin = () => {
    http.get('me')
      .then(({ data }) => {
        setUser(data.user);
      });
  };

  useEffect(() => {
    if (!user) {
      getUserLogin();
    }
  }, [token, user]);

  useEffect(() => {
    localStorage.setItem('token', token);
  }, [token]);

  const sidebar = token && <Sidebar />;

  return (
    <AuthContext.Provider value={{ token, user, removeToken: () => setToken(null) }}>
      <Router>
        <InjectAxiosInterceptors />

        {sidebar}

        <section
          className={`container ${token ? 'md:pl-56 ml-8' : 'mx-auto px-8'} pt-8`}
        >
          <Suspense fallback={<p>Loading...</p>}>
            <Switch>
              <Route path='/users' component={UserRoutes} />
              <Route path='/posts' component={PostRoutes} />
              <Route path='/login'>
                <Login afterLogin={afterLogin} />
              </Route>
              <Route path='/' exact component={Home} />
            </Switch>
          </Suspense>
        </section>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
